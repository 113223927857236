import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  }
}));

const ProductCard = ({ className, product, DialogPlayVideo, ...rest }) => {
  const classes = useStyles();
  return (
    <>
      <CardMedia className={classes.media} image={product.imgthumbnail} />
      <CardContent>
        {/* <Box display="flex" justifyContent="center" mb={3}>
          <Avatar alt="Product" src={product.media} variant="square" />
        </Box> */}
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.course_name}
        </Typography>
        <Typography align="center" color="textPrimary" variant="body1">
          {product.aboutthecourse}
        </Typography>
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={3}>
        <Grid container justify="space-between" spacing={3} fullWidth>
          <Box p={1} width="100%">
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() =>
                DialogPlayVideo(product.videoSrc, product.course_name)
              }
            >
              <PlayCircleOutlineIcon
                className={classes.statsIcon}
                color="#ffffff"
              />
              เล่นวิดีโอตัวอย่าง
            </Button>
          </Box>
          {/* <Box p={1} width="100%">
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              เวลาวิดีโอ 1 ชั่วโมง 34 นาที{'  '}
            </Typography>
            <CheckCircleOutlineIcon style={{color: '#4caf50'}} />
          </Box> */}
          <Box p={1} width="100%">
            <PeopleAltIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              จำนวนผู้ลงทะเบียน <b>{product.count_regis}</b> คน{'  '}
            </Typography>
            {/* <CheckCircleOutlineIcon style={{color: '#4caf50'}} /> */}
          </Box>
          <Box p={1} width="100%">
            <DescriptionIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              แบบทดสอบก่อนเรียน{'  '}
            </Typography>
            {product.example_before == true && (
              <CheckCircleOutlineIcon style={{ color: '#4caf50' }} />
            )}
            {product.example_before == false && (
              <HighlightOffIcon className={classes.statsIcon} color="action" />
            )}
          </Box>
          <Box p={1} width="100%">
            <DescriptionIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              แบบทดสอบหลังเรียน{'  '}
            </Typography>
            {product.example_after == true && (
              <CheckCircleOutlineIcon style={{ color: '#4caf50' }} />
            )}
            {product.example_after == false && (
              <HighlightOffIcon className={classes.statsIcon} color="action" />
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
