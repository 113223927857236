import React from 'react';

const Logo = (props) => {
  return (<>
    <img
    alt="Logo"
    src={require('../images/logo-tbar.png')}

    style={{height: 60, marginRight: -15}}
  />
    <img
      alt="Logo"
      src={require('../images/logo.png')}

      style={{height: 60}}
    /></>
  );
};

export default Logo;
