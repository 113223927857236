import React, { Component } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Page from 'src/components/Page';
import { withStyles } from '@material-ui/styles';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';

import { url } from '../../api';
import Cookies from 'js-cookie';

const useStyles = {
  root: {}
};

class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      noterror: false
    };
  }
  handlesubmit = async (e, values) => {
    let formData = new FormData();
    formData.append('username', values.username);
    formData.append('password', values.password);
    const requestOptions = {
      method: 'POST',
      body: formData
    };
    let response = await fetch(url.login, requestOptions);
    let { status, accesstoken } = await response.json();
    if ((status == 'success')) {
      Cookies.set('__session', accesstoken);
      // this.props.history.push(`/capp/dashboard`);
      document.location = "/app/dashboard";
      this.setState({
        error: false,
        noterror: true
      });
    } else {
      Cookies.remove('__session');
      this.setState({
        error: true,
        noterror: false
      });
    }
  };

  render() {
    const { classes, className } = this.props;
    return (
      <Page className={classes.root} title="Login">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <br />
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Sign in
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Sign in on the internal platform
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <br />
            </Grid>
            <Box mt={3} mb={1}>
              {this.state.error == true && (
                <>
                  <Alert severity="error">
                    <AlertTitle>ผิดพลาด</AlertTitle>
                    กรุณาตรวจสอบชื่อผู้ใช้งานและรหัสผ่านให้ถูกต้อง
                  </Alert>
                </>
              )}
              {this.state.noterror == true && (
                <>
                  <Alert severity="success">
                    <AlertTitle>ถูกต้อง</AlertTitle>
                    กรุณารอสักครู่...
                  </Alert>
                </>
              )}
            </Box>
            <Box my={2}>
              <AvForm onValidSubmit={this.handlesubmit}>
                {/* With AvField */}
                <AvField
                  name="username"
                  label="ชื่อผู้ใช้งาน"
                  type="text"
                  required
                  errorMessage="กรุณากรอกชื่อผู้ใช้งาน"
                  autoFocus
                />
                <AvField
                  name="password"
                  label="รหัสผ่าน"
                  required
                  type="password"
                  errorMessage="กรุณากรอกรหัสผ่าน"
                />

                <Button
                  color="primary"
                  // disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  เข้าสู่ระบบ
                </Button>
              </AvForm>
            </Box>
          </Container>
        </Box>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(LoginView));
