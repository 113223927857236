import React, { useRef, createRef, useState, useEffect } from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/dist/plyr.css';

// const videoSrc = {
//   type: 'video',
//   sources: [
//     {
//       src: 'AZ_t9iAJRDw',
//       provider: 'youtube'
//     },
//     {
//       src:
//         'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4',
//       type: 'video/mp4',
//       size: 720
//     },
//     {
//       src:
//         'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4',
//       type: 'video/mp4',
//       size: 1080
//     }
//   ]
// };
export default function Player({ videoSrc }) {
  const ref = useRef();
  useEffect(() => {
    // console.log(ref.current.plyr);
    timeto();
    // setInterval(() => {

    // }, 1000);
  }, []);

  function timeto() {
    // console.log(ref.current.plyr.currentTime);
    //ref.current.plyr.currentTime = 1
    ref.current.plyr.play();
  }

  return (
    <>
      <Plyr autoplay={true} ref={ref} source={videoSrc} />
    </>
  );
}
