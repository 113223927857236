import React, { Component } from 'react';
import { Box, Container, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import axios from 'axios';
import { url } from '../../api';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';

import Cookies from 'js-cookie';
import { checkauthen } from '../../api';

const useStyles = theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});

export class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      count: 0,
    };
    // alert("1")
    this.getLogs();

    this.Searchusers = this.Searchusers.bind(this);

    this.arrayholder = [];

    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });
  }

  getLogs = async () => {
    const formData = new FormData();
    formData.append('action', 'getlogs');
    const response = await axios.post(url.logs, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result, count } = response.data;
    // console.log(result);
    if (status == 'success') {
      this.setState(
        {
          logs: result,
          count: count
        },
        () => {
          this.arrayholder = result;
        }
      );
    } else {
      this.setState({ logs: [], count: 0 });
    }
  };

  Searchusers = e => {
    const text = e.target.value;
    const newData = this.arrayholder.filter(item => {
      const itemData = item.name_th.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      category: newData
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Page className={classes.root} title="Logs">
        <Container maxWidth={false}>
          <Toolbar
            ActionDialogAddEdit={this.ActionDialogAddEdit}
            Searchusers={this.Searchusers}
          />
          <Box mt={3}>
            <Results
              count={this.state.count}
              logs={this.state.logs}
            />
          </Box>
        </Container>
      </Page>
    );
  }
}

// export default withStyles(useStyles)(CustomerListView);

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Logs));
