import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import { withStyles } from '@material-ui/styles';
import axios from 'axios';
import { url } from '../../api';
import Cookies from 'js-cookie';

const useStyles = theme => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
});

export class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: '0'
    };
  }
  componentDidMount() {
    this.getCount();
  }

  getCount = async () => {
    let formData = new FormData();
    formData.append('action', 'getAllregister');
    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { count } = response.data;
    this.setState({
      count: count
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <AppBar style={{backgroundColor: "#BDBDBD"}} className={classes.root} elevation={0}>
        <Toolbar>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box flexGrow={1} />
          <Hidden mdDown>
            <IconButton color="inherit">
              <RouterLink to="/" style={{ color: '#ffffff' }}>
                <Badge badgeContent={this.state.count} color="error">
                  <NotificationsIcon />
                </Badge>
              </RouterLink>
            </IconButton>
            <Button
              style={{ color: '#FFFFFF' }}
              onClick={() => {
                Cookies.remove('__session');
                document.location = '/login';
              }}
            >
              <IconButton color="inherit">
                <InputIcon />
              </IconButton>
            </Button>
          </Hidden>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={this.props.onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(useStyles)(TopBar);
