import React, { Component } from 'react'

import {
  Box,
  Container
} from '@material-ui/core';
import Page from 'src/components/Page';
import { withStyles } from '@material-ui/styles';
import Form from './Form'

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';


const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});




export class Add extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { classes, } = this.props;
    console.log("xxx", this.props)

    return (
      <Page
      className={classes.root}
      title="Settings"
    >
      <Container maxWidth="lg">
        <Form />
      </Container>
    </Page>
    )
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Add));
