import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import VolumeUp from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles({
  root: {
    width: 500
  },
  input: {
    width: 42
  }
});

export default function InputSlider({ example_quiz_percent, updatePercent }) {
  const classes = useStyles();

  const handleSliderChange = (event, newValue) => {
    updatePercent(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>เปอร์เซ็นต์ความถูกต้อง</Grid>
        <Grid item xs>
          <Slider
            value={example_quiz_percent}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <span className={classes.input}>{example_quiz_percent}</span>%
        </Grid>
      </Grid>
    </div>
  );
}
