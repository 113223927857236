import React, { useCallback, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Listregister from './Listregister';
import { ToastContainer, toast } from 'react-toastify';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useNavigate
} from 'react-router-dom';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Edit = () => {
  const classes = useStyles();
  let { id } = useParams();
  let navigate = useNavigate();

  function BacktoHome() {
    navigate('/app/products');
  }

  return (
    <Page className={classes.root} title="ผู้สมัครอบรมออนไลน์">
      <ToastContainer />
      <Container maxWidth="lg">
        <Listregister id={id} BacktoHome={BacktoHome} />
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
