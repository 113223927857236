import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Offline, Online, Detector } from 'react-detect-offline';

import Toolbar from './Toolbar';

import { url } from '../../api';
import { loadVideo } from './getDuration';
import Player from './player';

const polling = {
  enabled: true,
  interval: 2000,
  timeout: 1000
};

const useStyles = {
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'white',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  }
};

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff'
  }
}))(LinearProgress);

export class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course_category_id: '',
      course_id: '',
      course_name: '',
      leaning_path: [],
      leaning_path_id: '',
      dialogAddcontent: false,
      dialogVideos: false,
      videodetail: {},
      filevideo: null,
      percent: 0,
      hidupload: false,
      duration: 0,
      videoSrc: {},
      typeVideo: 480,
      dialogdelete: false,
      leaning_path_delete_id: '',
      dialogDocument: false,
      DocumentData: {},
      dialogdeleteDocument: false,
      document_file: null
    };
    this.getCourse(this.props.id);
    this.getLeaning_path(this.props.id);

    this.openAddcontent = this.openAddcontent.bind(this);
  }

  getCourse = async course_id => {
    const formData = new FormData();
    formData.append('action', 'getcourse');
    formData.append('course_id', course_id);
    const response = await axios.post(url.content, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    if (status == 'success' && result.length > 0) {
      this.setState({ course_name: result[0].course_name });
    } else {
      this.setState({ course_name: '' });
    }
  };

  getLeaning_path = async course_id => {
    const formData = new FormData();
    formData.append('action', 'getLeaning_path');
    formData.append('course_id', course_id);
    const response = await axios.post(url.content, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    // console.log(result)
    if (status == 'success') {
      this.setState({ leaning_path: result });
    } else {
      this.setState({ leaning_path: [] });
    }
  };

  handleSubmit = async (event, values) => {
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'insertlearningpath');
    formData.append('leaning_path_name', values.leaning_path_name);
    formData.append('course_id', this.props.id);
    const response = await axios.post(url.course, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg, sql } = response.data;
    // console.log(response.data)
    if (status == 'success') {
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        quizlist: [],
        example_name: ''
      });
      window.location.reload();
    } else {
      await toast.error(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
    }
    this.props.BlockUiAction(false);
  };

  handleSubmitSavevideo = async (event, values) => {
    this.props.BlockUiAction(true);
    this.setState({
      hidupload: true
    });
    const config = {
      // timeout: 100000,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
        this.setState({
          percent: (this.state.percent = percentCompleted)
        });
      }.bind(this)
    };

    try {
      console.log(this.state.filevideo);
      const formData = new FormData();
      formData.append('action', 'addvideo');
      formData.append('duration', this.state.duration);
      formData.append('typeVideo', this.state.typeVideo);
      formData.append('filevideo', this.state.filevideo);
      formData.append('leaning_path_id', this.state.leaning_path_id);
      const response = await axios.post(url.content, formData, config);
      const { status } = response.data;
      this.setState({
        dialogAddcontent: false
      });
      window.location.reload();
    } catch (err) {
      // throw new Error('Unable to establish a login session.'); // here I'd like to send the error to the user instead
      console.log(err);
    }

    //if (status == 'success') {
    //   await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
    //     position: 'top-center',
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     hideProgressBar: false,
    //     'z-index': 999999
    //   });
    //   this.setState({
    //     quizlist: [],
    //     example_name: ''
    //   });
    //   this.props.BacktoHome();
    //this.setState({
    // hidupload: false
    //});
    //} else {
    //   await toast.error(msg, {
    //     position: 'top-center',
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     hideProgressBar: false,
    //     'z-index': 999999
    //   });
    //}
    // this.props.BlockUiAction(false);
  };

  onChangeFile = async (e, v) => {
    const files = e.target.files[0];
    const video = await loadVideo(e.target.files[0]);
    const duration = video.duration;
    // console.log(duration)

    this.setState({
      filevideo: files,
      duration: duration
    });
    // console.log(this.state.filevideo)
  };

  openAddcontent = () => {
    this.setState({
      dialogAddcontent: true
    });
  };

  closeAddcontent = () => {
    this.setState({
      dialogAddcontent: false
    });
    window.location.reload();
  };

  openAddVideo = async (detail, leaning_path_id) => {
    // console.log(detail.type)
    const formData = new FormData();
    formData.append('action', 'getVideo');
    formData.append('leaning_path_id', leaning_path_id);
    formData.append('get', detail.type);
    const response = await axios.post(url.content, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    this.setState({
      dialogVideos: true,
      leaning_path_id: leaning_path_id,
      videodetail: detail,
      videoSrc: (this.state.videoSrc = result[0].videoSrc),
      typeVideo: detail.type
    });
    // console.log(this.state.videoSrc)
  };

  closeAddVideo = () => {
    this.setState({
      dialogVideos: false,
      leaning_path_id: '',
      videodetail: {}
    });
    window.location.reload();
  };

  confirmdelete = leaning_path_id => {
    this.setState({
      dialogdelete: true,
      leaning_path_delete_id: leaning_path_id
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false,
      leaning_path_delete_id: ''
    });
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'delete_leaning_path');
    formData.append('leaning_path_id', this.state.leaning_path_delete_id);

    const response = await axios.post(url.course, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    // console.log(response.data)
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        leaning_path_delete_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  openDocument = obj => {
    //leaning_path_id
    // console.log(obj);
    this.setState({
      DocumentData: (this.state.DocumentData = obj),
      dialogDocument: true,
      leaning_path_id: obj.leaning_path_id
    });
  };

  confirmdeleteDocument = leaning_path_id => {
    this.setState({
      dialogdeleteDocument: true,
      leaning_path_delete_id: leaning_path_id
    });
  };

  DeleteDocument = async () => {
    this.setState({
      dialogdeleteDocument: (this.state.dialogdeleteDocument = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'delete_Document');
    formData.append('leaning_path_id', this.state.leaning_path_delete_id);

    const response = await axios.post(url.content, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    // console.log(response.data)
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      let arr = this.state.DocumentData;
      arr['document_file'] = '';

      this.setState({
        DocumentData: (this.state.DocumentData = arr)
      });
      // console.log(this.state.DocumentData)
    }
    // window.location.reload();
    this.props.BlockUiAction(false);
  };

  onChangeFileDocument = (e, v) => {
    // console.log(e.target.files[0])
    this.setState({
      document_file: e.target.files[0]
    });
    
  };

  SaveDocument = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'addDocument');
    formData.append('document_file', this.state.document_file);
    formData.append('leaning_path_id', this.state.leaning_path_id);

    const response = await axios.post(url.content, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    // console.log(response.data)
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        leaning_path_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  }

  render() {
    const { classes, className } = this.props;
    return (
      <>
        <ToastContainer />
        <Toolbar openAddcontent={this.openAddcontent} />
        <Card>
          <CardHeader
            subheader="ส่วนจัดการและเพิ่มเนื้อหาให้กับคอร์ส"
            title={`จัดการเนื้อหาคอร์ส${this.state.course_name}`}
          />
          <Divider />
          <CardContent>
            <Detector
              render={({ online }) => {
                return (
                  <div>
                    <Dialog
                      open={!online}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      disableBackdropClick={true}
                      maxWidth="md"
                      fullWidth={true}
                    >
                      <DialogContent>
                        <DialogContentText>
                          <h2>oop! ขออภัยอินเทอร์เน็ตขัดข้องครับ</h2>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          onClick={() => window.location.reload()}
                          color="primary"
                        >
                          ตกลง
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                );
              }}
            />
            <Grid container spacing={10} wrap="wrap">
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">ชื่อหัวข้อ</TableCell>
                        <TableCell align="center">วิดีโอ 480p</TableCell>
                        <TableCell align="center">วิดีโอ 720p</TableCell>
                        <TableCell align="center">วิดีโอ 1080p</TableCell>
                        <TableCell align="center">เอกสารประกอบ</TableCell>
                        <TableCell align="center">ลบ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.leaning_path.map((obj, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {obj.leaning_path_name}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                style={{
                                  backgroundColor: '#2979ff',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() =>
                                  this.openAddVideo(
                                    { type: 480 },
                                    obj.leaning_path_id
                                  )
                                }
                              >
                                <VideoCallIcon style={{ color: '#ffffff' }} />
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#2979ff',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() =>
                                  this.openAddVideo(
                                    { type: 720 },
                                    obj.leaning_path_id
                                  )
                                }
                              >
                                <VideoCallIcon style={{ color: '#ffffff' }} />
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#2979ff',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() =>
                                  this.openAddVideo(
                                    { type: 1080 },
                                    obj.leaning_path_id
                                  )
                                }
                              >
                                <VideoCallIcon style={{ color: '#ffffff' }} />
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#33691E',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() => {
                                  this.openDocument(obj);
                                }}
                              >
                                <DescriptionOutlinedIcon
                                  style={{ color: '#ffffff' }}
                                />
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#ff3d00',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() =>
                                  this.confirmdelete(obj.leaning_path_id)
                                }
                              >
                                <DeleteForeverIcon
                                  style={{ color: '#ffffff' }}
                                />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
          {/*  <Divider />
           <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                บันทึกข้อมูล
              </Button>
            </Box> */}
        </Card>
        <Dialog
          open={this.state.dialogAddcontent}
          onClose={() => this.closeAddcontent()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
          maxWidth="xl"
          fullWidth={true}
        >
          <AvForm onValidSubmit={this.handleSubmit}>
            <DialogTitle id="alert-dialog-title">
              <h4>{'เพิ่มเนื้อหาใหม่'}</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <AvField
                  type="text"
                  name="leaning_path_name"
                  onChange={this.setCategory}
                  value={this.state.leaning_path_name}
                  label="ชื่อหัวข้อ : "
                  placeholder="ชื่อหัวข้อ"
                  disabled={false}
                  errorMessage="กรุณากรอกชื่อหัวข้อ"
                  validate={{ required: { value: true } }}
                ></AvField>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.closeAddcontent()} color="primary">
                ยกเลิก
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                autoFocus
              >
                บันทึกข้อมูล
              </Button>
            </DialogActions>
          </AvForm>
        </Dialog>

        <Dialog
          open={this.state.dialogVideos}
          onClose={() => this.closeAddVideo()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
          maxWidth="md"
          fullWidth={true}
        >
          <AvForm onValidSubmit={this.handleSubmitSavevideo}>
            <DialogTitle id="alert-dialog-title">
              <h4>{'จัดการวิดีโอ'}</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <AvField
                  id="video"
                  name="video"
                  label={`ไฟล์วิดีโอคุณภาพ ${this.state.videodetail.type}p`}
                  placeholder="ไฟล์วิดีโอคุณภาพ 480p"
                  type="file"
                  onChange={this.onChangeFile}
                  errorMessage={`กรุณาเลือกไฟล์วิดีโอคุณภาพ ${this.state.videodetail.type}p`}
                  validate={{ required: { value: true } }}
                />
                {this.state.hidupload == false && (
                  <>
                    <Button
                      onClick={() => this.closeAddVideo()}
                      variant="contained"
                      color="secondary"
                    >
                      ปิด
                    </Button>
                    {'   '}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      บันทึกข้อมูล
                    </Button>
                  </>
                )}
              </DialogContentText>
              <BorderLinearProgress
                variant="determinate"
                value={this.state.percent}
              />
              {this.state.percent}
              {' %'}

              {this.state.videoSrc.sources != null && (
                <>
                  <h6>วิดีโอตัวอย่างดังด้านล่าง</h6>
                  <Player videoSrc={this.state.videoSrc} />
                </>
              )}
            </DialogContent>
            <DialogActions></DialogActions>
          </AvForm>
        </Dialog>

        <Dialog
          open={this.state.dialogdelete}
          onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบข้อมูล ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmDelete()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleDelete()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogDocument}
          // onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{this.state.DocumentData.leaning_path_name}</h4>
          </DialogTitle>
          <DialogContent>
            {this.state.DocumentData.document_file == '' && (
              <span style={{ color: '#FF0000' }}>ไม่มีเอกสารประกอบ</span>
            )}
            {this.state.DocumentData.document_file != '' && (
              <>
                <a href={this.state.DocumentData.document_file} target="_blank">
                  <Button color="primary" variant="contained">
                    <DescriptionOutlinedIcon style={{ color: '#ffffff' }} />{' '}
                    ดูเอกสาร
                  </Button>
                </a>{' '}
                <Button
                  onClick={() => {
                    this.confirmdeleteDocument(
                      this.state.DocumentData.leaning_path_id
                    );
                  }}
                  color="secondary"
                  variant="contained"
                >
                  <DeleteForeverIcon style={{ color: '#ffffff' }} /> ลบเอกสาร
                </Button>
              </>
            )}
            {/* {JSON.stringify(this.state.DocumentData)} */}
            <br />
            <br />
            <input type="file" onChange={this.onChangeFileDocument} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  dialogDocument: (this.state.dialogDocument = false)
                });
              }}
              color="primary"
            >
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.SaveDocument()
              }}
              variant="contained"
              autoFocus
            >
              บันทึกข้อมูล
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogdeleteDocument}
          onClose={() => {
            this.setState({
              dialogdeleteDocument: (this.state.dialogdeleteDocument = false),
              leaning_path_delete_id: ''
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบข้อมูล ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  dialogdeleteDocument: (this.state.dialogdeleteDocument = false),
                  leaning_path_delete_id: ''
                });
              }}
              color="primary"
            >
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.DeleteDocument()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Content));
