import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../../actions';
import CardMedia from '@material-ui/core/CardMedia';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = {
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'white',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  media: {
    height: 0,
    paddingTop: '30.25%'
  },
  large: {
    width: 90,
    height: 90
  }
};

export class Form extends Component {
  render() {
    const { classes, className } = this.props;
    return (
      <form className={clsx(classes.root, className)}>
        <ToastContainer />
        <Card>
          <AvForm onValidSubmit={this.handleSubmit}>
            <CardHeader
              subheader={`แสดงรายชื่อสมาชิกทั้งหมด ${this.props.count} คน`}
              title="ข้อมูลสมาชิก"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={10} wrap="wrap">
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <Divider />
                  <PerfectScrollbar>
                    <Box minWidth={800}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>ชื่อ - นามสกุล</TableCell>
                            <TableCell>ที่อยู่</TableCell>
                            <TableCell>อีเมล</TableCell>
                            <TableCell>เบอร์โทร</TableCell>
                            <TableCell>ลบ</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.customers.map((order, index) => {
                            return (
                              <TableRow hover key={order.users_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  {order.prefix_name}
                                  {order.firstname} {order.lastname}
                                </TableCell>
                                <TableCell>{order.address}</TableCell>
                                <TableCell>{order.address}</TableCell>
                                <TableCell>{order.tel}</TableCell>
                                <TableCell>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    style={{
                                      backgroundColor: '#f44336',
                                      color: '#ffffff',
                                      justifyContent: 'flex-start'
                                    }}
                                    onClick={() => {
                                      this.props.showConfirmDelete(
                                        order.users_id
                                      );
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      style={{ color: '#ffffff' }}
                                    />{' '}
                                    ลบ
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </CardContent>
          </AvForm>
        </Card>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Form));
