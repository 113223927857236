import React, { Component } from 'react';

import { url, checkauthen } from '../../api';
import Cookies from 'js-cookie';
export default class index extends Component {
  constructor(props) {
    super(props);
    Cookies.remove('__session');
    document.location = '/login';
  }
  render() {
    return <div></div>;
  }
}
