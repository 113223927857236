import React, { Component } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import { withStyles } from '@material-ui/styles';
import Cookies from 'js-cookie';
import { checkauthen } from '../../../api';

const useStyles = {
  root: {}
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });
  }
  render() {
    let { classes } = this.props;
    return (
      <>
        <Page
          className={classes.root}
          title="Dashboard"
          style={{ paddingBottom: 3, paddingTop: 3 }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TotalCustomers />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Budget />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TasksProgress />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TotalProfit />
              </Grid>
              {/* <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales />
          </Grid>
         <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid> */}
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <LatestOrders />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </>
    );
  }
}

export default withStyles(useStyles)(Dashboard);
