import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  NavLink as RouterLink
} from 'react-router-dom';

export default function FadeMenu({ product, handleCloseright }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (i, course_id, example_type_id) => {
    if (course_id != 'backdropClick') {
      handleCloseright(example_type_id, course_id);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color="action" />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {/* <MenuItem
          onClick={e => {
            handleClose(e, product.course_id);
          }}
        >
          จัดการเนื้อหาคอร์ส
        </MenuItem> */}
        <MenuItem onClick={e => handleClose(e, product.course_id, 1)}>
          แบบทดสอบก่อนเรียน
        </MenuItem>
        <MenuItem onClick={e => handleClose(e, product.course_id, 2)}>
          แบบทดสอบหลังเรียน
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/app/products/contentcourse/${product.course_id}`}
        >
          จัดการเนื้อหาคอร์ส
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/app/products/editcourse/${product.course_id}`}
        >
          แก้ไขรายละเอียดคอร์ส
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/app/products/Lecturer/${product.course_id}`}
        >
          จัดการข้อมูลวิทยากรและลายเซ็นใบประกาศนียบัตร
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/app/products/Signature/${product.course_id}`}
        >
          ลายเซ็นใบประกาศนียบัตร
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to={`/app/products/registercourse/${product.course_id}`}
        >
          ดูรายชื่อผู้ลงทะเบียน
        </MenuItem>
        <MenuItem onClick={e => handleClose(e, product.course_id, 4)}>
          ลบคอร์ส
        </MenuItem>
        {/* <MenuItem onClick={e => handleClose(e, 3)}>ลบคอร์ส</MenuItem> */}
      </Menu>
    </div>
  );
}
