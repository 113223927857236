import Cookies from 'js-cookie';
export const url = {
  course: 'https://mli.pcru.ac.th/adminapi/course.php',
  quiz: 'https://mli.pcru.ac.th/adminapi/quiz.php',
  category: 'https://mli.pcru.ac.th/adminapi/category.php',
  registercourse: 'https://mli.pcru.ac.th/adminapi/registercourse.php',
  content: 'https://mli.pcru.ac.th/adminapi/content.php',
  Lecturer: 'https://mli.pcru.ac.th/adminapi/Lecturer.php',
  Signature: 'https://mli.pcru.ac.th/adminapi/Signature.php',
  Customers: 'https://mli.pcru.ac.th/adminapi/Customers.php',
  Settings: 'https://mli.pcru.ac.th/adminapi/Settings.php',
  Report: 'https://mli.pcru.ac.th/adminapi/Report.php',
  login: 'https://mli.pcru.ac.th/adminapi/login.php',
  checklogin: 'https://mli.pcru.ac.th/adminapi/checklogin.php',
  logs: 'https://mli.pcru.ac.th/adminapi/logs.php'
};

export const accesstoken = () => {
  const jwt = Cookies.get('__session');
  return jwt;
};

export const checkauthen = async () => {
  let token = Cookies.get('__session');
  let formData = new FormData();
  formData.append('token', token);

  const requestOptions = {
    method: 'POST',
    body: formData
  };

  let response = await fetch(url.checklogin, requestOptions);
  const res = await response.json();
  return res;
};

export const logOut = () => {
  Cookies.remove('__session');
};
