import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  Chip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';
import CardMedia from '@material-ui/core/CardMedia';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { url } from '../../api';

const useStyles = {
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'white',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  table: {
    minWidth: 650
  }
};

export class Listregister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course_id: '',
      listregister: [],
      course_name: '',
      datatransfer: false,
      dialogactivate: false,
      dialogcancle: false,
      dialogdelete: false,
      sliptranfer: '',
      course_shoping_id: '',
      price_title: '',
      price_thb_title: ''
    };
    this.getCategory(this.props.id);
    // console.log(this.props.id)
  }

  getCategory = async course_id => {
    const formData = new FormData();
    formData.append('action', 'getregister');
    formData.append('course_id', course_id);
    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result, price_title, price_thb_title } = response.data;
    // console.log(result);
    if (status == 'success' && result.length > 0) {
      this.setState({
        listregister: result,
        course_name: result[0].course_name,
        price_title: price_title,
        price_thb_title: price_thb_title
      });
    } else {
      this.setState({
        listregister: [],
        course_name: '',
        price_tile: '',
        price_thb_title: ''
      });
    }
  };

  ConfirmSave = async () => {
    // alert(values.course_id);
    this.setState({
      dialogactivate: (this.state.dialogactivate = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'activateregister');
    formData.append('course_shoping_id', this.state.course_shoping_id);

    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status } = response.data;
    // console.log(sql);
    if (status == 'success') {
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        course_shoping_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  CancleSave = async () => {
    this.setState({
      dialogcancle: (this.state.dialogcancle = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'unactivateregister');
    formData.append('course_shoping_id', this.state.course_shoping_id);

    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status } = response.data;
    // console.log(sql);
    if (status == 'success') {
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        course_shoping_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'deleteregister');
    formData.append('course_shoping_id', this.state.course_shoping_id);

    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status } = response.data;
    // console.log(sql);
    if (status == 'success') {
      await toast.success('ดำเนินการลบข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        course_shoping_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  onChangeFile = (e, v) => {
    this.setState({
      imgthumbnail: e.target.files[0]
    });
  };

  setCategory = e => {
    this.setState({
      course_category_id: e.target.value
    });
  };

  ViewTransfer = data => {
    // alert(data);
    this.setState({
      sliptranfer: data,
      datatransfer: true
    });
  };

  closeDialogTransfer = () => {
    this.setState({
      datatransfer: false
    });
  };

  closeConfirm = () => {
    this.setState({
      dialogactivate: false,
      course_shoping_id: ''
    });
  };

  showConfirm = course_shoping_id => {
    this.setState({
      dialogactivate: true,
      course_shoping_id: course_shoping_id
    });
  };

  showConfirmCancle = course_shoping_id => {
    this.setState({
      dialogcancle: true,
      course_shoping_id: course_shoping_id
    });
  };

  closeConfirmCancle = () => {
    this.setState({
      dialogcancle: false,
      course_shoping_id: ''
    });
  };

  showConfirmDelete = course_shoping_id => {
    this.setState({
      dialogdelete: true,
      course_shoping_id: course_shoping_id
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false,
      course_shoping_id: ''
    });
  };

  render() {
    const { classes, className } = this.props;
    return (
      <Card>
        <CardHeader
          subheader="แสดงรายชื่อทั้งหมดที่ลงทะเบียนอบรมออนไลน์คอร์สนี้"
          title={`ผู้สมัครอบรมออนไลน์${this.state.course_name}`}
        />
        <Divider />
        <CardContent>
          <div style={{fontWeight: 'bold'}}>
            ประเภทคอร์ส: {this.state.price_title > 0 && <>เสียเงิน ({this.state.price_thb_title} บาท)</>}
            {this.state.price_title <= 0 && <>ฟรี</>}
          </div>
          {/* <PerfectScrollbar> */}
          <Grid container spacing={10} wrap="wrap">
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="center">ชื่อ - นามสกุล</TableCell>
                      <TableCell align="center">ที่อยู่</TableCell>
                      <TableCell align="center">อีเมล</TableCell>
                      <TableCell align="center">เบอร์โทร</TableCell>
                      <TableCell align="center">สถานะ</TableCell>
                      <TableCell align="center">ลบ</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.listregister.map((obj, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            {obj.prefix_name}
                            {obj.firstname}
                            {obj.lastname}
                          </TableCell>
                          <TableCell align="left">{obj.address}</TableCell>
                          <TableCell align="left">{obj.email}</TableCell>
                          <TableCell align="left">{obj.tel}</TableCell>
                          <TableCell align="left">
                            {/* ส่วนที่คอร์สไม่ฟรี */}
                            {obj.approve == 'activate' && obj.price > 0 && (
                              <Chip
                                style={{
                                  backgroundColor: '#4caf50',
                                  color: '#ffffff'
                                }}
                                label={'อนุมัติแล้ว'}
                                size="small"
                              />
                            )}
                            {obj.approve == 'unactivate' && obj.price > 0 && (
                              <Chip
                                style={{
                                  backgroundColor: '#ffac33',
                                  color: '#ffffff'
                                }}
                                label="รอการอนุมัติ..."
                                size="small"
                              />
                            )}
                            {obj.approve == 'untransfer' && obj.price > 0 && (
                              <Chip
                                style={{
                                  backgroundColor: '#ff1744',
                                  color: '#ffffff'
                                }}
                                label="ยังไม่ได้โอนเงิน"
                                size="small"
                              />
                            )}
                            {/* ส่วนคอร์สฟรี */}
                            {obj.approve == 'unactivate' && obj.price <= 0 && (
                              <Chip
                                style={{
                                  backgroundColor: '#ffac33',
                                  color: '#ffffff'
                                }}
                                label="รอการอนุมัติ..."
                                size="small"
                              />
                            )}
                            {obj.approve == 'activate' && obj.price <= 0 && (
                              <Chip
                                style={{
                                  backgroundColor: '#4caf50',
                                  color: '#ffffff'
                                }}
                                label={'อนุมัติแล้ว'}
                                size="small"
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {obj.payment.length > 0 && (
                              <>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.ViewTransfer(obj.sliptransfer)
                                  }
                                  type="submit"
                                  style={{
                                    width: 200,
                                    backgroundColor: '#4caf50',
                                    color: '#ffffff',
                                    justifyContent: 'flex-start'
                                  }}
                                >
                                  <ReceiptIcon
                                    style={{ marginRight: 5, color: '#ffffff' }}
                                  />
                                  หลักฐานการโอนเงิน
                                </Button>
                                <br />
                                <br />
                                {obj.approve == 'unactivate' && (
                                  <>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      style={{
                                        width: 200,
                                        backgroundColor: '#2979ff',
                                        color: '#ffffff',
                                        justifyContent: 'flex-start'
                                      }}
                                      onClick={() =>
                                        this.showConfirm(obj.course_shoping_id)
                                      }
                                    >
                                      <AssignmentTurnedInIcon
                                        style={{
                                          marginRight: 5,
                                          color: '#ffffff'
                                        }}
                                      />
                                      เปิดสิทธิ์ให้รายชื่อนี้
                                    </Button>
                                    <br />
                                    <br />
                                  </>
                                )}

                                {obj.approve == 'activate' && (
                                  <>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      style={{
                                        width: 200,
                                        backgroundColor: '#ffc107',
                                        color: '#ffffff',
                                        justifyContent: 'flex-start'
                                      }}
                                      onClick={() =>
                                        this.showConfirmCancle(
                                          obj.course_shoping_id
                                        )
                                      }
                                    >
                                      <AssignmentTurnedInIcon
                                        style={{
                                          marginRight: 5,
                                          color: '#ffffff'
                                        }}
                                      />
                                      ยกเลิกสิทธิ์รายชื่อนี้
                                    </Button>
                                    <br />
                                    <br />
                                  </>
                                )}
                              </>
                            )}
                            {/* ส่วนคอร์สฟรี */}
                            {obj.price == 0 && (
                              <>
                                {obj.approve == 'unactivate' && (
                                  <>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      style={{
                                        width: 200,
                                        backgroundColor: '#2979ff',
                                        color: '#ffffff',
                                        justifyContent: 'flex-start'
                                      }}
                                      onClick={() =>
                                        this.showConfirm(obj.course_shoping_id)
                                      }
                                    >
                                      <AssignmentTurnedInIcon
                                        style={{
                                          marginRight: 5,
                                          color: '#ffffff'
                                        }}
                                      />
                                      เปิดสิทธิ์ให้รายชื่อนี้
                                    </Button>
                                    <br />
                                    <br />
                                  </>
                                )}
                                {obj.approve == 'activate' && (
                                  <>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                      style={{
                                        width: 200,
                                        backgroundColor: '#ffc107',
                                        color: '#ffffff',
                                        justifyContent: 'flex-start'
                                      }}
                                      onClick={() =>
                                        this.showConfirmCancle(
                                          obj.course_shoping_id
                                        )
                                      }
                                    >
                                      <AssignmentTurnedInIcon
                                        style={{
                                          marginRight: 5,
                                          color: '#ffffff'
                                        }}
                                      />
                                      ยกเลิกสิทธิ์รายชื่อนี้
                                    </Button>
                                    <br />
                                    <br />
                                  </>
                                )}
                              </>
                            )}
                            <Button
                              color="secondary"
                              variant="contained"
                              type="submit"
                              style={{
                                width: 200,
                                backgroundColor: '#f44336',
                                color: '#ffffff',
                                justifyContent: 'flex-start'
                              }}
                              onClick={() =>
                                this.showConfirmDelete(obj.course_shoping_id)
                              }
                            >
                              <DeleteForeverIcon
                                style={{ marginRight: 5, color: '#ffffff' }}
                              />
                              ลบรายชื่อนี้ออก
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {/* </PerfectScrollbar> */}
        </CardContent>
        {/* <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" type="submit">
            บันทึกข้อมูล
          </Button>
        </Box> */}

        <Dialog
          open={this.state.datatransfer}
          onClose={() => this.closeDialogTransfer()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            <h4>แสดงหลักฐานการโอนเงิน</h4>
          </DialogTitle>
          <DialogContent>
            {/* <CardMedia
              className={classes.media}
              image={this.state.sliptranfer}
            /> */}
            <img src={this.state.sliptranfer} style={{ width: '100%' }} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.closeDialogTransfer()}
              color="primary"
              autoFocus
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogactivate}
          onClose={() => this.closeConfirm()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการเปิดสิทธิ์ ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              โปรดคลิก "ยืนยัน"
              เพื่อเปิดสิทธิ์ให้ผู้ลงทะเบียนคอร์สนี้ให้สามารถเรียนออนไลน์ได้
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirm()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.ConfirmSave()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogcancle}
          onClose={() => this.closeConfirmCancle()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการยกเลิกสิทธิ์ ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              โปรดคลิก "ยืนยัน"
              เพื่อยกเลิกสิทธิ์ให้ผู้ลงทะเบียนคอร์สนี้ให้ไม่สามารถเรียนออนไลน์ได้
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmCancle()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleSave()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogdelete}
          onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบรายชื่อ ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              โปรดคลิก "ยืนยัน" เพื่อลบผู้ลงทะเบียนคอร์สนี้
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmDelete()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleDelete()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Listregister));
