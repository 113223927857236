import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, Searchusers,  ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" m={1}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="ค้นหาชื่อคอร์ส"
            variant="outlined"
            onChange={Searchusers}
          />
        </Box>
        <Box display="flex"  m={1}>
          {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button> */}
          <Button color="primary" variant="contained" component={RouterLink} to="/app/products/add">
            <AddIcon color="#FFFFFF" /> เพิ่มคอร์สใหม่
          </Button>
          
        </Box>
      </Box>
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end" >
              <Box maxWidth={600}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search customer"
                  variant="outlined"
                />
              </Box>
              <Box maxWidth={600}>
                {""}<Button color="primary" variant="contained">
                  <AddIcon color="#FFFFFF" /> เพิ่มคอร์สใหม่
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
