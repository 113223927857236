import { FETCHING_DATA, FETCHING_DATA_SUCCESS, FETCHING_DATA_FAILURE } from '../constants'
const initialState = {
    data: [],
    isFetching: false,
    isError: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_DATA:
            return { ...state, isFetching: true, data: [] };
        case FETCHING_DATA_SUCCESS:
            return { ...state, isFetching: true, data: action.payload };
        case FETCHING_DATA_FAILURE:
            return { ...state, isFetching: false, isError: true };
        default:
            return state
    }
};