import React, { Component } from 'react';
import { Box, Container, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import axios from 'axios';
import { url } from '../../api';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';

import { ToastContainer, toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Cookies from 'js-cookie';
import { checkauthen } from '../../api';

const useStyles = theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});

export class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      dialogdelete: false,
      dialogAddEdit: false,
      actionform: 'เพิ่มหมวดหมู่ใหม่',
      course_category_id: '',
      take: 'add',
      count: 0,
      name_th: ''
    };
    // alert("1")
    this.getCustomers();

    this.Searchusers = this.Searchusers.bind(this);
    this.showConfirmDelete = this.showConfirmDelete.bind(this);
    this.ActionDialogAddEdit = this.ActionDialogAddEdit.bind(this);

    this.arrayholder = [];

    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });
  }

  getCustomers = async () => {
    const formData = new FormData();
    formData.append('action', 'getcategory');
    const response = await axios.post(url.category, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result, count } = response.data;
    // console.log(result);
    if (status == 'success') {
      this.setState(
        {
          category: result,
          count: count
        },
        () => {
          this.arrayholder = result;
        }
      );
    } else {
      this.setState({ category: [], count: 0 });
    }
  };

  Searchusers = e => {
    const text = e.target.value;
    const newData = this.arrayholder.filter(item => {
      const itemData = item.name_th.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      category: newData
    });
  };

  showConfirmDelete = course_category_id => {
    this.setState({
      dialogdelete: true,
      course_category_id: course_category_id
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false,
      course_category_id: ''
    });
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogAddEdit = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('course_category_id', this.state.course_category_id);

    const response = await axios.post(url.category, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        users_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  ActionDialogAddEdit = (action, actionform, order, take) => {
    this.setState({
      dialogAddEdit: action,
      actionform: actionform,
      course_category_id: order.course_category_id,
      name_th: (this.state.name_th = order.name_th),
      take: take
    });
    // setTimeout(function(){ document.getElementById("name_th").focus(); }, 1000);
  };

  handleSubmitSave = async (e, v) => {
    this.setState({
      dialogdelete: (this.state.dialogAddEdit = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'addedit');
    formData.append('course_category_id', this.state.course_category_id);
    formData.append('name_th', v.name_th);
    formData.append('take', this.state.take);

   

    const response = await axios.post(url.category, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        users_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  render() {
    const { classes } = this.props;
    return (
      <Page className={classes.root} title="Category">
        <Container maxWidth={false}>
          <Toolbar
            ActionDialogAddEdit={this.ActionDialogAddEdit}
            Searchusers={this.Searchusers}
          />
          <Box mt={3}>
            <Results
              count={this.state.count}
              category={this.state.category}
              showConfirmDelete={this.showConfirmDelete}
              ActionDialogAddEdit={this.ActionDialogAddEdit}
            />
          </Box>
        </Container>
        <Dialog
          open={this.state.dialogdelete}
          onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบหมวดหมู่ ?'}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmDelete()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleDelete()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.dialogAddEdit}
          onClose={() => this.ActionDialogAddEdit(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
          fullWidth={true}
        >
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <DialogContent>
              <AvField
                name="name_th"
                id="name_th"
                value={this.state.name_th}
                label={this.state.actionform}
                placeholder={this.state.actionform}
                type="text"
                errorMessage="กรุณากรอกข้อมูล"
                validate={{ required: { value: true } }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.ActionDialogAddEdit(false, '', {}, 'add')}
                color="primary"
              >
                ยกเลิก
              </Button>
              <Button color="primary" variant="contained" type="submit">
                ยืนยัน
              </Button>
            </DialogActions>
          </AvForm>
        </Dialog>
      </Page>
    );
  }
}

// export default withStyles(useStyles)(CustomerListView);

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Category));
