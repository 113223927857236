import React, { Component } from 'react';
import { Box, Container, Grid, Button, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Pagination } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import clsx from 'clsx';

import { url } from '../../../api';

import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';

// import data from './data';
import Player from './player';
import Quiz from './quiz';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../../actions';

import Menu1 from './Menu';

import Cookies from 'js-cookie';
import { checkauthen } from '../../../api';

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
});

export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoTitle: '',
      videoSrc: {},
      showDialoVideo: false,
      showDialoQuiz: false,
      menuright: false,
      playing: false,
      data: [],
      course_id: 0,

      example_name: '',
      quizlist: [],
      example_quiz_percent: 0,
      CircularProgress: true,
      action_quiz: 'add',
      example_type_id: null,

      dialogdelete: false
    };

    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });

    //Quiz js
    this.DialogQuiz = this.DialogQuiz.bind(this);
    this.closeDialogShowQuiz = this.closeDialogShowQuiz.bind(this);

    this.SaveData = this.SaveData.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleChangeInputTitle = this.handleChangeInputTitle.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeexample_name = this.handleChangeexample_name.bind(this);
    this.addMoreChoice = this.addMoreChoice.bind(this);
    this.removeMoreChoice = this.removeMoreChoice.bind(this);
    this.addMoreQuiz = this.addMoreQuiz.bind(this);
    this.removeMoreQuiz = this.removeMoreQuiz.bind(this);
    this.handleCloseright = this.handleCloseright.bind(this);
    this.updatePercent = this.updatePercent.bind(this);
    this.showConfirmDelete = this.showConfirmDelete.bind(this);
    this.closeConfirmDelete = this.closeConfirmDelete.bind(this);
    this.Searchusers = this.Searchusers.bind(this);

    //end quiz js
    this.getCourse();

    this.props.BlockUiAction(false);

    this.arrayholder = [];


  }

  componentDidMount() {
    this.props.BlockUiAction(true);
  }

  getCourse = async () => {
    let formData = new FormData();
    formData.append('action', 'getcourse');
    const response = await axios.post(url.course, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    // console.log(status);
    if (status == 'success') {
      this.setState(
        {
          data: result
        },
        () => {
          // In this block you can do something with new state.
          this.arrayholder = result;
        }
      );
    } else {
      this.setState({
        data: []
      });
    }
    this.props.BlockUiAction(false);
  };

  DialogPlayVideo = (videoSrc, videoTitle) => {
    // console.log(videoSrc)
    this.setState({
      showDialoVideo: true,
      videoSrc: (this.state.videoSrc = videoSrc),
      videoTitle: (this.state.videoTitle = videoTitle)
    });
  };

  closeDialogShowvideos = () => {
    this.setState({
      showDialoVideo: false,
      videoSrc: (this.state.videoSrc = {}),
      videoTitle: (this.state.videoTitle = '')
    });
  };

  DialogQuiz = () => {
    this.setState({
      showDialoQuiz: true
    });
  };

  LoadQuiz = async () => {
    let formData = new FormData();
    formData.append('action', 'getquiz');
    formData.append('example_type_id', this.state.example_type_id);
    formData.append('course_id', this.state.course_id);
    const response = await axios.post(url.quiz, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const {
      status,
      result,
      example_name,
      example_quiz_percent,
      action_quiz
    } = response.data;
    // alert(action_quiz)
    if (status == 'success') {
      this.setState({
        quizlist: result,
        example_name: example_name,
        example_quiz_percent: example_quiz_percent,
        CircularProgress: false,
        action_quiz: 'edit'
      });
    } else {
      this.setState({
        quizlist: [],
        example_name: '',
        example_quiz_percent: 0,
        CircularProgress: false,
        action_quiz: 'add'
      });
    }
  };

  closeDialogShowQuiz = () => {
    this.setState({
      showDialoQuiz: false,
      quizlist: [],
      example_quiz_percent: 0
    });
  };

  openmenuright = event => {
    this.setState({
      menuright: true,
      anchorEl: event.currentTarget
    });
  };

  handleCloseright = (id, course_id) => {
    this.setState({
      menuright: false,
      course_id: (this.state.course_id = course_id),
      example_type_id: (this.state.example_type_id = id)
    });
    if (id == 1 || id == 2) {
      this.LoadQuiz();
      this.DialogQuiz();
    }
    if (id == 4) {
      this.showConfirmDelete();
    }
  };

  ///QUIZ js
  SaveData = async () => {
    this.props.BlockUiAction(true);
    let formData = new FormData();
    formData.append('action', 'addquiz');
    formData.append('action_quiz', this.state.action_quiz);
    formData.append('course_id', this.state.course_id);
    formData.append('example_quiz_percent', this.state.example_quiz_percent);
    formData.append('example_type_id', this.state.example_type_id);
    formData.append('example_name', this.state.example_name);
    formData.append('quizlist', JSON.stringify(this.state.quizlist));
    const response = await axios.post(url.quiz, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg, sql } = response.data;
    if (status == 'success') {
      this.closeDialogShowQuiz();
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        quizlist: [],
        example_name: ''
      });
      this.props.BlockUiAction(false);
    } else {
      this.closeDialogShowQuiz();
      await toast.error(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        quizlist: [],
        example_name: ''
      });
      this.props.BlockUiAction(false);
    }
    this.getCourse();
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'deletecourse');
    formData.append('course_id', this.state.course_id);

    const response = await axios.post(url.course, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status } = response.data;
    if (status == 'success') {
      await toast.success('ดำเนินการลบข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        course_id: 0
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  handleChangeRadio = (e, index) => {
    const arr = [...this.state.quizlist];
    arr[index].answer = e.target.value;
    this.setState(prevState => ({
      quizlist: (prevState.quizlist = arr)
    }));
  };

  handleChangeInputTitle = (e, index) => {
    const arr = [...this.state.quizlist];
    arr[index].title = e.target.value;
    this.setState(prevState => ({
      quizlist: (prevState.quizlist = arr)
    }));
    // console.log(this.state.quizlist)
  };

  handleChangeInput = (e, index1, index2) => {
    const arr = [...this.state.quizlist];
    arr[index1].choicelist[index2].value = e.target.value;
    this.setState(prevState => ({
      quizlist: (prevState.quizlist = arr)
    }));
  };

  handleChangeexample_name = e => {
    this.setState({
      example_name: e.target.value
    });
  };

  addMoreChoice = index => {
    const arr = [...this.state.quizlist];
    arr[index].choicelist.push({ value: '' });
    this.setState(prevState => ({
      quizlist: (prevState.quizlist = arr)
    }));
  };

  removeMoreChoice = (index1, index2) => {
    const arr = [...this.state.quizlist];
    arr[index1].choicelist.splice(index2, 1);
    this.setState(prevState => ({
      quizlist: (prevState.quizlist = arr)
    }));
  };

  addMoreQuiz = () => {
    const q = {
      title: '',
      answer: null,
      choicelist: [
        {
          value: ''
        }
      ]
    };

    this.setState(prevState => ({
      quizlist: [...prevState.quizlist, q]
    }));
    // this.scrollToBottom();
  };

  removeMoreQuiz = i => {
    const quizlist = [...this.state.quizlist];
    quizlist.splice(i, 1);
    this.setState({ quizlist: (this.state.quizlist = quizlist) });
  };

  updatePercent = percent => {
    // console.log(percent);
    this.setState({
      example_quiz_percent: (this.state.example_quiz_percent = percent)
    });
    // console.log(this.state.example_quiz_percent)
  };

  showConfirmDelete = () => {
    this.setState({
      dialogdelete: true
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false
    });
  };

  Searchusers = e => {
    const text = e.target.value;
    const newData = this.arrayholder.filter(item => {
      const itemData = item.course_name.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      data: newData
    });
  };

  //Quiz js
  render() {
    const { classes } = this.props;
    return (
      <Page className={classes.root} title="Products">
        <ToastContainer />
        <Container maxWidth={false}>
          <Toolbar Searchusers={this.Searchusers} />
          <Box mt={3}>
            <Grid container spacing={3}>
              {this.state.data.map((product, index) => {
                return (
                  <Grid item key={product.course_id} lg={4} md={6} xs={12}>
                    <Card>
                      <Box display="flex" justifyContent="flex-end">
                        <Menu1
                          product={product}
                          handleCloseright={this.handleCloseright}
                        />
                      </Box>
                      <ProductCard
                        className={classes.productCard}
                        product={product}
                        DialogPlayVideo={this.DialogPlayVideo}
                      />{' '}
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {/* <Box mt={3} display="flex" justifyContent="center">
            <Pagination color="primary" count={3} size="small" />
          </Box> */}
        </Container>

        <Dialog
          open={this.state.showDialoVideo}
          onClose={() => this.closeDialogShowvideos()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            <h4>{this.state.videoTitle}</h4>
          </DialogTitle>
          <DialogContent>
            <Player videoSrc={this.state.videoSrc} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.closeDialogShowvideos()}
              color="primary"
              autoFocus
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>
        <Quiz
          course_id={this.state.course_id}
          DialogQuiz={this.DialogQuiz}
          closeDialogShowQuiz={this.closeDialogShowQuiz}
          showDialoQuiz={this.state.showDialoQuiz}
          SaveData={this.SaveData}
          handleChangeRadio={this.handleChangeRadio}
          handleChangeInputTitle={this.handleChangeInputTitle}
          handleChangeInput={this.handleChangeInput}
          handleChangeexample_name={this.handleChangeexample_name}
          addMoreChoice={this.addMoreChoice}
          removeMoreChoice={this.removeMoreChoice}
          addMoreQuiz={this.addMoreQuiz}
          example_name={this.state.example_name}
          quizlist={this.state.quizlist}
          removeMoreQuiz={this.removeMoreQuiz}
          example_quiz_percent={this.state.example_quiz_percent}
          updatePercent={this.updatePercent}
          CircularProgress={this.state.CircularProgress}
          example_type_id={this.state.example_type_id}
        />

        <Dialog
          open={this.state.dialogdelete}
          onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบคอร์ส ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              โปรดคลิก "ยืนยัน" เพื่อลบคอร์สนี้
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmDelete()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleDelete()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(ProductList));
