import React, { Component } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';

import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import axios from 'axios';
import { url } from '../../../api';

import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: '#FF0000',
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

class TotalProfit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const formData = new FormData();
    formData.append('action', 'getCourse');
    const response = await axios.post(url.Report, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { count } = response.data;
    this.setState({
      count: count
    });
  };
  render() {
    let { classes } = this.props;

    return (
      <Card className={classes.root}>
        <CardContent>
          <Grid container justify="space-between" spacing={3}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="h6">
              จำนวนคอร์สเรียนที่มีอยู่ในระบบทั้งหมด 
              </Typography>
              <Typography color="textPrimary" variant="h3">
                {this.state.count} คอร์ส
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                style={{ backgroundColor: '#1976d2', height: 56, width: 56 }}
              >
                <CastForEducationIcon />
              </Avatar>
            </Grid>
          </Grid>
          {/* <Box mt={2} display="flex" alignItems="center">
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            16%
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Since last month
          </Typography>
        </Box> */}
        </CardContent>
      </Card>
    );
  }
}
export default withStyles(useStyles)(TotalProfit);


