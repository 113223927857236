const init = {
  data: []
};
export default (state = init, action) => {
  switch (action.type) {
    case 'DATA':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
