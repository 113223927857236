import React, { Component } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import axios from 'axios';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../../actions';
import { url, checkauthen } from '../../../api';

import Cookies from 'js-cookie';

const useStyles = {
  root: {}
};

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: {
        email: ''
      }
    };
    this.getEmail();

    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });
  }

  getEmail = async () => {
    const formData = new FormData();
    formData.append('action', 'getEmail');
    const response = await axios.post(url.Settings, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, email, payment } = response.data;
    // console.log(email);
    if (status == 'success') {
      this.setState({
        setting: {
          email: (this.state.email = email),
          payment: (this.state.payment = payment)
        }
      });
    } else {
      this.setState({
        setting: {
          email: '',
          payment: ''
        }
      });
    }
  };

  handleSubmit = async (event, values) => {
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'updateemail');
    formData.append('email', values.email);
    formData.append('payment', values.payment);
    const response = await axios.post(url.Settings, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = await response.data;
    // console.log(status);
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      window.location.reload();
    } else {
      await toast.error(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
    }
    this.props.BlockUiAction(false);
  };

  render() {
    const { classes, className } = this.props;
    return (
      <form className={(classes.root, className)}>
        <Card>
          <AvForm onValidSubmit={this.handleSubmit}>
            <CardHeader subheader="ตั้งค่าระบบ" title="ตั้งค่า" />
            <Divider />
            <CardContent>
              <AvField
                fullWidth
                name="email"
                label="อีเมลผู้ดูแลระบบ"
                placeholder="อีเมลผู้ดูแลระบบ"
                type="text"
                value={this.state.setting.email}
              />
              <AvField
                fullWidth
                name="payment"
                label="รายละเอียดการโอนเงิน"
                placeholder="รายละเอียดการโอนเงิน"
                type="textarea"
                style={{ height: '200px' }}
                value={this.state.setting.payment}
              />
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button type="submit" color="primary" variant="contained">
                บันทึกข้อมูล
              </Button>
            </Box>
          </AvForm>
        </Card>
      </form>
    );
  }
}

// export default Password;

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Password));
