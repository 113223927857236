import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import BlockUi from 'react-block-ui';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import 'react-block-ui/style.css';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';


//import redux
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const App = ({ apiBlockUI }) => {
  const classes = useStyles();
  const routing = useRoutes(routes);
  // console.log(apiBlockUI)
  return (
    <>
      <Backdrop open={apiBlockUI.display} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </>
  );
};

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

// export default App;
export default connect(mapStateToProps)(App);
