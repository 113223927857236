const init = {
    display: false
}
export default (state = init, action) => {
    switch (action.type) {
        case 'DISPLAY':
            return { display: true }
        case 'UNDISPLAY':
            return { display: false }
        default:
            return state
    }
}
