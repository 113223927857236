import {
  FETCHING_DATA,
  FETCHING_DATA_SUCCESS,
  FETCHING_DATA_FAILURE
} from '../constants';
import people from './api';

export const setStateToSccess = data => ({
  type: FETCHING_DATA_SUCCESS,
  payload: data
});

export const setStateToFetching = data => ({
  type: FETCHING_DATA
});

export const setStateToFailure = data => ({
  type: FETCHING_DATA_FAILURE
});

export const fetchAPI = () => {
  return dispatch => {
    dispatch(setStateToFetching());
    people()
      .then(result => {
        dispatch(setStateToSccess(result));
      })
      .catch(error => {
        dispatch(setStateToFailure());
      });
  };
};

//block UI
export const setStateBlockUI = () => ({
  type: 'DISPLAY'
});

export const setStateUnBlockUI = () => ({
  type: 'UNDISPLAY'
});

export const BlockUiAction = action => {
  return dispatch => {
    if (action == false) {
      dispatch(setStateUnBlockUI());
    } else {
      dispatch(setStateBlockUI());
    }
  };
};

//QUIZ

export const setQuizDATA = data => ({
  type: 'DATA',
  payload: data
});

export const QUIZ = data => {
  return dispatch => {
    dispatch({
      type: 'DATA',
      payload: data
    });
  };
};
