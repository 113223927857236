import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';
import CardMedia from '@material-ui/core/CardMedia';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { url } from '../../api';

const useStyles = {
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'white',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  },
  media: {
    height: 0,
    paddingTop: '30.25%'
  },
  large: {
    width: 90,
    height: 90
  }
};

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEdit: [],
      course_name: '',
      actiontitle: 'เพิ่มข้อมูลใหม่',
      teacher_id: '',
      teacher_name: '',
      position: '',
      imgthumbnail: '',
      signature_img: '',
      action_type: 'add',
      dialogdelete: false,
      showthumbnail: false,
      showthumbnailSig: false
    };
    this.getLecturerEdit(this.props.id);
    this.getCoursename(this.props.id);
    this.setState({
      course_id: (this.state.course_id = this.props.id)
    });
  }

  getCoursename = async course_id => {
    const formData = new FormData();
    formData.append('action', 'getCourse');
    formData.append('course_id', course_id);
    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, course_name } = response.data;
    // console.log(course_name);
    if (status == 'success') {
      this.setState({
        course_name: course_name
      });
    } else {
      this.setState({ course_name: '' });
    }
  };

  getLecturerEdit = async course_id => {
    const formData = new FormData();
    formData.append('action', 'getLecturerEdit');
    formData.append('course_id', course_id);
    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    // console.log(result);
    if (status == 'success') {
      this.setState({
        dataEdit: result
      });
    } else {
      this.setState({ dataEdit: [] });
    }
  };

  getLecturerFormEdit = async teacher_id => {
    this.setState({
      teacher_id: (this.state.teacher_id = teacher_id)
    });
    const formData = new FormData();
    formData.append('action', 'getLecturerFormEdit');
    formData.append('teacher_id', teacher_id);
    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    // console.log(result);
    if (status == 'success') {
      this.setState({
        teacher_name: result[0]['teacher_name'],
        position: result[0]['position'],
        picture: result[0]['picture'],
        signature_img: result[0]['signature_img'],
        actiontitle: 'แก้ไขข้อมูล',
        action_type: 'edit',
        showthumbnail: true,
        showthumbnailSig: true
      });
    } else {
    }
  };

  handleSubmit = async (event, values) => {
    // alert(values.course_id);
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', this.state.action_type);
    formData.append('course_id', this.state.course_id);
    formData.append('teacher_id', this.state.teacher_id);
    formData.append('teacher_name', this.state.teacher_name);
    formData.append('position', this.state.position);
    // alert(this.state.signature_img);
    formData.append('signature_img', this.state.signature_img);
    formData.append('imgthumbnail', this.state.imgthumbnail);
    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    // console.log(await response.data);
    const { status, msg } = await response.data;
    // console.log(status);
    if (status == 'success') {
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        dataEdit: [],
        course_name: '',
        actiontitle: 'เพิ่มข้อมูลใหม่',
        teacher_id: '',
        teacher_name: '',
        position: '',
        imgthumbnail: '',
        action_type: 'add',
        showthumbnail: false,
        showthumbnailSig: false
      });
      window.location.reload();
      // this.props.BacktoHome();
    } else {
      await toast.error(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
    }
    this.props.BlockUiAction(false);
  };

  onChangeFile = (e, v) => {
    this.setState({
      imgthumbnail: e.target.files[0],
      showthumbnail: false
    });
  };

  onChangeFilesignature_img = (e, v) => {
    this.setState({
      signature_img: this.state.signature_img = e.target.files[0],
      showthumbnailSig: false
    });
    // console.log(this.state.signature_img)
  };

  onChangeteacher_name = e => {
    this.setState({
      teacher_name: e.target.value
    });
  };
  onChangeposition = e => {
    this.setState({
      position: e.target.value
    });
  };

  showConfirmDelete = teacher_id => {
    this.setState({
      dialogdelete: true,
      teacher_id: teacher_id
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false,
      teacher_id: ''
    });
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('teacher_id', this.state.teacher_id);

    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    // console.log(sql);
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        teacher_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  SingatureSelect = async (e, v, index, course_id) => {
    const teacher_id = this.state.dataEdit[index]['teacher_id'];
    const formData = new FormData();
    formData.append('action', 'updateSingature');
    formData.append('teacher_id', teacher_id);
    formData.append('course_id', course_id);

    const response = await axios.post(url.Lecturer, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
  };

  render() {
    const { classes, className } = this.props;
    return (
      <form className={clsx(classes.root, className)}>
        <ToastContainer />
        <Card>
          <AvForm onValidSubmit={this.handleSubmit}>
            <CardHeader
              subheader={`คอร์สเรียน: ${this.state.course_name}`}
              title="จัดการข้อมูลวิทยากร"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={10} wrap="wrap">
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>ชื่อ - นามสกุล</TableCell>
                        <TableCell>ตำแหน่ง</TableCell>
                        <TableCell>รูปภาพ</TableCell>
                        <TableCell>ผู้ออกลายเซ็น</TableCell>
                        <TableCell>ลายเซ็น</TableCell>
                        <TableCell>แก้ไข / ลบ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.dataEdit.map((order, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{(index + 1)}</TableCell>
                            <TableCell>{order.teacher_name}</TableCell>
                            <TableCell>{order.position}</TableCell>
                            <TableCell>
                              <Avatar
                                src={order.picture}
                                className={classes.large}
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                type="radio"
                                name="Signature"
                                value={order.signature}
                                defaultChecked={order.signature}
                                onChange={e =>
                                  this.SingatureSelect(
                                    e,
                                    order.signature,
                                    index,
                                    order.course_id
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#ff9800',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() => {
                                  this.getLecturerFormEdit(order.teacher_id);
                                }}
                              >
                                <EditIcon style={{ color: '#ffffff' }} /> แก้ไข
                              </Button>
                              &nbsp;/&nbsp;
                              <Button
                                color="secondary"
                                variant="contained"
                                style={{
                                  backgroundColor: '#f44336',
                                  color: '#ffffff',
                                  justifyContent: 'flex-start'
                                }}
                                onClick={() => {
                                  this.showConfirmDelete(order.teacher_id);
                                }}
                              >
                                <DeleteForeverIcon
                                  style={{ color: '#ffffff' }}
                                />{' '}
                                ลบ
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardHeader
              subheader={`จัดการข้อมูลวิทยากร`}
              title={this.state.actiontitle}
            />
            <CardContent>
              <Grid container spacing={10} wrap="wrap">
                <Grid className={classes.item} item md={8} sm={10} xs={12}>
                  <AvField
                    name="teacher_name"
                    value={this.state.teacher_name}
                    label="ชื่อ - นามสกุล :"
                    placeholder="ชื่อ - นามสกุล"
                    type="text"
                    onChange={this.onChangeteacher_name}
                    errorMessage="กรุณากรอกชื่อ - นามสกุล"
                    // validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="position"
                    value={this.state.position}
                    label="ตำแหน่ง :"
                    placeholder="ตำแหน่ง"
                    type="text"
                    errorMessage="กรุณากรอกตำแหน่ง"
                    onChange={this.onChangeposition}
                    // validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="signature_img"
                    label="ลายเซ็น ขนาด 400x150 px"
                    placeholder="ลายเซ็น"
                    type="file"
                    onChange={this.onChangeFilesignature_img}
                    errorMessage="กรุณาเลือกรูปภาพ ขนาด 400x150 px"
                    // validate={{ required: { value: true } }}
                  />

                  {this.state.showthumbnailSig === true && (
                    <img
                      style={{ width: 400, height: 150 }}
                      src={this.state.signature_img}
                    />
                  )}
                  <AvField
                    name="imgthumbnail"
                    label="รูปภาพวิทยากร ขนาด 400x150 px"
                    placeholder="รูปภาพวิทยากร"
                    type="file"
                    onChange={this.onChangeFile}
                    errorMessage="กรุณาเลือกรูปภาพ ขนาด 400x150 px"
                    // validate={{ required: { value: true } }}
                  />
                  {this.state.showthumbnail === true && (
                    <Avatar
                      src={this.state.picture}
                      className={classes.large}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <Box display="flex" justifyContent="flex-start" p={2}>
              <Button color="primary" variant="contained" type="submit">
                บันทึกข้อมูล
              </Button>
            </Box>
          </AvForm>

          <Dialog
            open={this.state.dialogdelete}
            onClose={() => this.closeConfirmDelete()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
          >
            <DialogTitle id="alert-dialog-title">
              {'โปรดยืนยันการลบรายชื่อ ?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                โปรดคลิก "ยืนยัน" เพื่อลบข้อมูล
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.closeConfirmDelete()} color="primary">
                ยกเลิก
              </Button>
              <Button
                color="primary"
                onClick={() => this.CancleDelete()}
                variant="contained"
                autoFocus
              >
                ยืนยัน
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Form));
