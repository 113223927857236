import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Grid, Card, Box, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../../actions';

import { url } from '../../../api';
import axios from 'axios';

import Slider from './Slider';

export class quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: null
    };
    // this.listContainer = React.createRef();
    // this.updatePercentFunc = this.updatePercentFunc.bind(this);
  }

  componentDidMount() {}

  // scrollToBottom = () => {
  //   this.listContainer.scrollTop = this.listContainer.scrollHeight;
  // };

  render() {
    // const props = this.props;
    return (
      <>
        <Dialog
          open={this.props.showDialoQuiz}
          onClose={() => this.props.closeDialogShowQuiz()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
          fullWidth={true}
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {
              this.props.example_type_id == 1 && (<h2>แบบทดสอบก่อนเรียน</h2>)
            }
            {
              this.props.example_type_id == 2 && (<h2>แบบทดสอบหลังเรียน</h2>)
            }
          </DialogTitle>
          <DialogContent>
           
            {this.props.CircularProgress == true && (
              <Grid lg={12} md={12} xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress color="secondary" />
                </Box>
              </Grid>
            )}

            {this.props.CircularProgress == false && (
              <>
                <TextField
                  fullWidth
                  style={{ width: '500px' }}
                  label="คำอธิบาย เช่น แบบทดสอบก่อนเรียนวิชาคอมพิวเตอร์เบื้องต้น"
                  name="firstName"
                  size="small"
                  variant="outlined"
                  required
                  value={this.props.example_name}
                  onChange={this.props.handleChangeexample_name}
                />
                <div>&nbsp;</div>
                <Slider
                  example_quiz_percent={this.props.example_quiz_percent}
                  updatePercent={this.props.updatePercent}
                />
                <div>&nbsp;</div>
                <h4>{`จำนวนคำถาม ${this.props.quizlist.length} คำถาม`}</h4>

                <Grid lg={12} md={12} xs={12}>
                  {this.props.quizlist.map((obj, index) => {
                    return (
                      <Box key={index} p={1}>
                        <Card>
                          <Box display="flex" justifyContent="start" p={1}>
                            <Grid item md={12} xs={12}>
                              <RadioGroup
                                aria-label="quiz"
                                name="quiz"
                                value={2}
                              >
                                <FormControlLabel
                                  value="best"
                                  control={
                                    <>
                                      &nbsp;&nbsp;&nbsp;<b>{index + 1}</b>
                                      .&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                  }
                                  label={
                                    <span style={{ display: 'inline-block' }}>
                                      <TextField
                                        fullWidth
                                        style={{ width: '500px' }}
                                        label={`คำถามที่ ${index + 1}`}
                                        name="firstName"
                                        size="small"
                                        variant="outlined"
                                        required
                                        value={obj.title}
                                        onChange={e =>
                                          this.props.handleChangeInputTitle(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      &nbsp;
                                      <Button
                                        onClick={() =>
                                          this.props.addMoreChoice(index)
                                        }
                                        variant="contained"
                                      >
                                        <AddIcon color="#FFFFFF" />{' '}
                                        เพิ่มตัวเลือก
                                      </Button>
                                      &nbsp;
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          this.props.removeMoreQuiz(index)
                                        }
                                      >
                                        <DeleteIcon color="#FFFFFF" />{' '}
                                        ลบคำถามที่ {index + 1}
                                      </Button>
                                    </span>
                                  }
                                />
                              </RadioGroup>
                            </Grid>
                          </Box>
                          <RadioGroup
                            aria-label={`quiz${index + 1}`}
                            name={`quiz${index + 1}`}
                            value={obj.answer}
                            onChange={e =>
                              this.props.handleChangeRadio(e, index)
                            }
                          >
                            {obj.choicelist.map((obj1, index1) => {
                              return (
                                <Box
                                  display="flex"
                                  justifyContent="start"
                                  p={1}
                                >
                                  <Grid item md={12} xs={12}>
                                    <FormControlLabel
                                      value={`${index1 + 1}`}
                                      control={<Radio />}
                                      label={
                                        <span
                                          style={{ display: 'inline-block' }}
                                        >
                                          <TextField
                                            fullWidth
                                            style={{ width: '500px' }}
                                            label={`ตัวเลือกที่ ${index1 + 1}`}
                                            name="firstName"
                                            size="small"
                                            variant="outlined"
                                            required
                                            value={obj1.value}
                                            onChange={e =>
                                              this.props.handleChangeInput(
                                                e,
                                                index,
                                                index1
                                              )
                                            }
                                          />
                                          &nbsp;
                                          {index1 > 0 && (
                                            <Button
                                              color="error"
                                              variant="contained"
                                              onClick={() =>
                                                this.props.removeMoreChoice(
                                                  index,
                                                  index1
                                                )
                                              }
                                            >
                                              <DeleteForeverIcon color="#FFFFFF" />
                                            </Button>
                                          )}
                                        </span>
                                      }
                                    />
                                  </Grid>
                                </Box>
                              );
                            })}
                          </RadioGroup>
                        </Card>
                      </Box>
                    );
                  })}
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {this.props.CircularProgress == false && (
              <>
                <Button
                  onClick={() => this.props.addMoreQuiz()}
                  color="secondary"
                  variant="contained"
                >
                  <AddIcon color="#FFFFFF" />
                  เพิ่มคำถาม
                </Button>
                <Button
                  onClick={() => this.props.SaveData()}
                  color="primary"
                  variant="contained"
                >
                  <SaveIcon color="#FFFFFF" />
                  บันทึก
                </Button>
                <Button
                  onClick={() => {
                    this.props.closeDialogShowQuiz();
                  }}
                  color="default"
                  variant="contained"
                >
                  <CloseIcon color="#FFFFFF" />
                  ปิด
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI,
  apiQUIZ: state.apiQUIZ
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect()(quiz);
