import React, { Component } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { url } from '../../../api';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
});

export class LatestOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.getAllOrder();
  }

  getAllOrder = async () => {
    let formData = new FormData();
    formData.append('action', 'getAllregister');
    const response = await axios.post(url.registercourse, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    console.log(result);
    if (status == 'success') {
      this.setState({
        data: result
      });
    } else {
      this.setState({
        data: []
      });
    }
    // console.log(this.state.data);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardHeader title="แสดงรายการผู้สมัครเรียนคอร์สออนไลน์เข้ามาในระบบ สถานะรอการยืนยันจากผู้ดูแลระบบ" />
        <Divider />
        <PerfectScrollbar>
          <Box minWidth={800}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Order Ref
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>ประเภทคอร์ส</TableCell>
                  <TableCell>ชื่อ - นามสกุล</TableCell>
                  <TableCell>คอร์สที่สมัคร</TableCell>
                  <TableCell>สถานะ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.map(order => (
                  <TableRow hover key={order.order_id}>
                    <TableCell>{order.order_id}</TableCell>
                    <TableCell>
                     {
                       order.price > 0 && (<>เสียเงิน ({order.price_thb} บาท)</>)
                     }
                     {
                       order.price <= 0 && (<>ฟรี</>)
                     }
                    </TableCell>
                    <TableCell>
                    {order.prefix_name}
                      {order.firstname} {order.lastname}
                    </TableCell>
                    <TableCell>
                      {/* {moment(order.createdAt).format('DD/MM/YYYY')} */}
                      {order.course_name}
                    </TableCell>
                    <TableCell>
                      <RouterLink
                        to={`/app/products/registercourse/${order.course_id}`}
                        style={{ color: '#ffffff', cursor: 'pointer' }}
                      >
                        <Chip
                          color="primary"
                          label="Pending"
                          size="small"
                          style={{ color: '#ffffff', cursor: 'pointer' }}
                        />
                      </RouterLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {/* <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
          >
            View all
          </Button>
        </Box> */}
      </Card>
    );
  }
}

export default withStyles(useStyles)(LatestOrders);
