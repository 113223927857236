import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  Label,
  AvCheckboxGroup,
  AvCheckbox
} from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../actions';
import moment from 'moment'
import { url } from '../../api';

const useStyles = {
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'white',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500
  }
};



export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course_category_id: '',
      course_name: '',
      aboutthecourse: '',
      time_period: '',
      lesson_content: '',
      target: '',
      criterion: '',
      objectivity: '',
      evaluation_criteria: '',
      video_preview_youtube: '',
      note: '',
      defineDate: false,
      start_registration: moment(new Date()).format('YYYY-MM-DD'),
      end_registration: moment(new Date()).format('YYYY-MM-DD'),
      price: '0',
      imgthumbnail: null,
      category: []
    };
    this.getCategory();
  }

  getCategory = async () => {
    const formData = new FormData();
    formData.append('action', 'getcategory');
    const response = await axios.post(url.category, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result } = response.data;
    if (status == 'success') {
      this.setState({ category: result });
    } else {
      this.setState({ category: [] });
    }
  };

  handleSubmit = async (event, values) => {
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'addCourse');
    formData.append('course_category_id', this.state.course_category_id);
    formData.append('course_name', values.course_name);
    formData.append('aboutthecourse', values.aboutthecourse);
    formData.append('time_period', values.time_period);
    formData.append('lesson_content', values.lesson_content);
    formData.append('target', values.target);
    formData.append('criterion', values.criterion);
    formData.append('objectivity', values.objectivity);
    formData.append('evaluation_criteria', values.evaluation_criteria);
    formData.append('video_preview_youtube', values.video_preview_youtube);
    formData.append('note', values.note);
    formData.append('defineDate', this.state.defineDate);
    formData.append('start_registration', values.start_registration);
    formData.append('end_registration', values.end_registration);
    formData.append('price', values.price);
    formData.append('imgthumbnail', this.state.imgthumbnail);
    const response = await axios.post(url.course, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg, sql } = response.data;
    // console.log(sql);
    if (status == 'success') {
      await toast.success('ดำเนินการบันทึกข้อมูลสำเร็จ :)', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        quizlist: [],
        example_name: ''
      });
    } else {
      await toast.error(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
    }
    setTimeout(
      function() {
        this.props.BlockUiAction(false);
        window.location.href = '/app/products';
      }.bind(this),
      3000
    );
  };

  onChangeFile = (e, v) => {
    this.setState({
      imgthumbnail: e.target.files[0]
    });
  };

  setCategory = e => {
    this.setState({
      course_category_id: e.target.value
    });
  };

  handleChangeDefineDate = e => {
    if (e.target.checked) {
      e.target.removeAttribute('checked');
      this.onC(true);
    } else {
      this.onC(false);
    }
    // if (target.checked) {
    //   target.removeAttribute('checked');
    //   this.onC()
    // } else {
    //   target.setAttribute('checked', true);

    // }

    // console.log(this.state.defineDate)
  };

  onC = s => {
    this.setState({ defineDate: (this.state.defineDate = s) });
    // console.log(this.state.defineDate)
  };

  render() {
    const { classes, className } = this.props;

    return (
      <form className={clsx(classes.root, className)}>
        <ToastContainer />
        <Card>
          <AvForm onValidSubmit={this.handleSubmit}>
            <CardHeader
              subheader="กรุณากรอกรายละเอียดทั้งหมดเกี่ยวกับคอร์สที่ต้องการเปิดเรียนออนไลน์"
              title="เพิ่มคอร์สเรียนออนไลน์"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={10} wrap="wrap">
                <Grid className={classes.item} item md={8} sm={10} xs={12}>
                  <AvField
                    type="select"
                    name="course_category_id"
                    onChange={this.setCategory}
                    label="หมวดหมู่คอร์ส : "
                    disabled={false}
                    errorMessage="กรุณาเลือกหมวดหมู่คอร์ส"
                    validate={{ required: { value: true } }}
                  >
                    <option value="">กรุณาเลือกหมวดหมู่คอร์ส....</option>
                    {this.state.category.map((obj, index) => {
                      return (
                        <option key={index} value={obj.course_category_id}>
                          {obj.name_th}
                        </option>
                      );
                    })}
                  </AvField>
                  <AvField
                    name="course_name"
                    value={this.state.course_name}
                    label="ชื่อคอร์ส :"
                    placeholder="ชื่อคอร์ส"
                    type="text"
                    errorMessage="กรุณากรอกชื่อคอร์ส"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="aboutthecourse"
                    value={this.state.aboutthecourse}
                    label="รายละเอียดเกี่ยวกับคอร์ส :"
                    placeholder="รายละเอียดเกี่ยวกับคอร์ส"
                    type="textarea"
                    errorMessage="กรุณากรอกรายละเอียดเกี่ยวกับคอร์ส"
                    style={{ height: '200px' }}
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="time_period"
                    value={this.state.time_period}
                    label="ช่วงเวลาที่เปิดเรียน :"
                    placeholder="ช่วงเวลาที่เปิดเรียน"
                    type="text"
                    errorMessage="กรุณากรอกช่วงเวลาที่เปิดเรียน"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="lesson_content"
                    value={this.state.lesson_content}
                    label="จำนวนเนื้อหา (บท) :"
                    placeholder="จำนวนเนื้อหา (บท)"
                    type="text"
                    errorMessage="กรุณากรอกช่วงจำนวนเนื้อหา"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="target"
                    value={this.state.target}
                    label="กลุ่มเป้าหมาย :"
                    placeholder="กลุ่มเป้าหมาย"
                    type="text"
                    errorMessage="กรุณากรอกกลุ่มเป้าหมาย"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="criterion"
                    value={this.state.criterion}
                    label="เกณฑ์การเรียน :"
                    placeholder="เกณฑ์การเรียน"
                    type="text"
                    errorMessage="กรุณากรอกเกณฑ์การเรียน"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="objectivity"
                    value={this.state.objectivity}
                    label="วัตถุประสงค์ :"
                    placeholder="วัตถุประสงค์"
                    type="textarea"
                    style={{ height: '200px' }}
                    errorMessage="กรุณากรอกวัตถุประสงค์"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="evaluation_criteria"
                    value={this.state.evaluation_criteria}
                    label="เกณฑ์การวัดและประเมินผล :"
                    placeholder="เกณฑ์การวัดและประเมินผล"
                    type="textarea"
                    style={{ height: '200px' }}
                    errorMessage="กรุณากรอกเกณฑ์การวัดและประเมินผล"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="video_preview_youtube"
                    value={this.state.video_preview_youtube}
                    label="Youtube ลิงค์"
                    placeholder="Youtube ลิงค์ เช่น https://www.youtube.com/watch?v=ioNng23DkIM&list=RDMM-JSINVNlyZE&index=3"
                    type="text"
                    errorMessage="กรุณากรอก Youtube ลิงค์"
                    validate={{ required: { value: true } }}
                  />
                  <AvCheckboxGroup name="defineregis" label="">
                    {/* <AvCheckbox
                      customInput
                      label="กำหนดช่วงเวลาลงทะเบียน"
                      value="true"
                    /> */}
                    <input
                      type="checkbox"
                      id="definedate"
                      checked={this.state.defineDate}
                      value={this.state.defineDate}
                      onClick={this.handleChangeDefineDate}
                    />{' '}
                    <label for="definedate">กำหนดช่วงเวลาลงทะเบียน</label>
                  </AvCheckboxGroup>

                  <AvField
                    name="start_registration"
                    value={this.state.start_registration}
                    label="ระยะเวลาเริ่มลงทะเบียน :"
                    placeholder="ระยะเวลาเริ่มลงทะเบียน"
                    type="date"
                  />
                  <AvField
                    name="end_registration"
                    value={this.state.end_registration}
                    label="ระยะเวลาสิ้นสุดการลงทะเบียน :"
                    placeholder="ระยะเวลาสิ้นสุดการลงทะเบียน"
                    type="date"
                  />
                  <AvField
                    name="price"
                    value={this.state.price}
                    label="ราคาคอร์ส :"
                    placeholder="ราคาคอร์ส"
                    type="number"
                  />
                  <AvField
                    name="note"
                    value={this.state.note}
                    label="หมายเหตุ :"
                    placeholder="หมายเหตุ"
                    type="textarea"
                    style={{ height: '200px' }}
                  />
                  <AvField
                    name="imgthumbnail"
                    label="รูปภาพ Thumbnail ขนาด 600x350 px"
                    placeholder="รูปภาพ Thumbnail"
                    type="file"
                    onChange={this.onChangeFile}
                    errorMessage="กรุณาเลือกรูปภาพ Thumbnail ขนาด 600x350 px"
                    validate={{ required: { value: true } }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                บันทึกข้อมูล
              </Button>
            </Box>
          </AvForm>
        </Card>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Form));
