import React, { Component } from 'react';
import { Box, Container, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import axios from 'axios';
import { url } from '../../../api';

//import redux
import { connect } from 'react-redux';
import { BlockUiAction, QUIZ } from '../../../actions';

import { ToastContainer, toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Cookies from 'js-cookie';
import { checkauthen } from '../../../api';

const useStyles = theme => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});

export class CustomerListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      dialogdelete: false,
      count: 0
    };
    // alert("1")
    this.getCustomers();

    this.Searchusers = this.Searchusers.bind(this);
    this.showConfirmDelete = this.showConfirmDelete.bind(this);

    this.arrayholder = [];

    checkauthen().then(res => {
      if (res.status == 'error') {
        Cookies.remove('__session');
        document.location = '/login';
      }
    });
  }

  getCustomers = async () => {
    const formData = new FormData();
    formData.append('action', 'getCustomers');
    const response = await axios.post(url.Customers, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, result, count } = response.data;
    // console.log(result);
    if (status == 'success') {
      this.setState(
        {
          customers: result,
          count: count
        },
        () => {
          this.arrayholder = result;
        }
      );
    } else {
      this.setState({ customers: [], count: 0 });
    }
  };

  Searchusers = e => {
    const text = e.target.value;
    const newData = this.arrayholder.filter(item => {
      const itemData = item.name.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      customers: newData
    });
  };

  showConfirmDelete = users_id => {
    this.setState({
      dialogdelete: true,
      users_id: users_id
    });
  };

  closeConfirmDelete = () => {
    this.setState({
      dialogdelete: false,
      users_id: ''
    });
  };

  CancleDelete = async () => {
    this.setState({
      dialogdelete: (this.state.dialogdelete = false)
    });
    this.props.BlockUiAction(true);
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('users_id', this.state.users_id);

    const response = await axios.post(url.Customers, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
    const { status, msg } = response.data;
    if (status == 'success') {
      await toast.success(msg, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        hideProgressBar: false,
        'z-index': 999999
      });
      this.setState({
        users_id: ''
      });
    }
    window.location.reload();
    this.props.BlockUiAction(false);
  };

  render() {
    const { classes } = this.props;
    return (
      <Page className={classes.root} title="Customers">
        <Container maxWidth={false}>
          <Toolbar Searchusers={this.Searchusers} />
          <Box mt={3} >
              <Results
                count={this.state.count}
                customers={this.state.customers}
                showConfirmDelete={this.showConfirmDelete}
              />
          </Box>
        </Container>
        <Dialog
          open={this.state.dialogdelete}
          onClose={() => this.closeConfirmDelete()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick={true}
        >
          <DialogTitle id="alert-dialog-title">
            {'โปรดยืนยันการลบรายชื่อ ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              โปรดคลิก "ยืนยัน" เพื่อลบข้อมูล
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeConfirmDelete()} color="primary">
              ยกเลิก
            </Button>
            <Button
              color="primary"
              onClick={() => this.CancleDelete()}
              variant="contained"
              autoFocus
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </Dialog>
      </Page>
    );
  }
}

// export default withStyles(useStyles)(CustomerListView);

const mapStateToProps = state => ({
  apiBlockUI: state.apiBlockUI
});

const mapDispatchToProps = {
  BlockUiAction,
  QUIZ
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CustomerListView));
